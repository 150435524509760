import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/style/index.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/Footer/facebook.svg");
;
import(/* webpackMode: "eager" */ "/app/src/components/Footer/gmri-logo-gray.svg");
;
import(/* webpackMode: "eager" */ "/app/src/components/Footer/instagram.svg");
;
import(/* webpackMode: "eager" */ "/app/src/components/Footer/twitter.svg");
;
import(/* webpackMode: "eager" */ "/app/src/components/Footer/youtube.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/NavBar/index.tsx");
