
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BodyType": [
      "AccordionBlock",
      "DefaultStreamBlock",
      "FeaturedProjectBlock",
      "FilteredProjectsBlock",
      "HeaderBlock",
      "ImageBlock",
      "OwnersBlock",
      "ParagraphBlock",
      "ParagraphCalloutBlock",
      "ParagraphImageBlock",
      "ParagraphImageFullBlock",
      "PartnersBlock",
      "QuoteBlock",
      "SelectedProjectBlock",
      "StepsBlock",
      "WithSidebarBlock"
    ],
    "MainContentsType": [
      "AccordionBlock",
      "DefaultStreamBlock",
      "ImageBlock",
      "OwnersBlock",
      "ParagraphBlock",
      "ParagraphCalloutBlock",
      "ParagraphImageBlock",
      "PartnersBlock",
      "QuoteBlock",
      "StepsBlock"
    ],
    "OverviewType": [
      "AccordionBlock",
      "DefaultStreamBlock",
      "ImageBlock",
      "OwnersBlock",
      "ParagraphBlock",
      "ParagraphCalloutBlock",
      "ParagraphImageBlock",
      "ParagraphImageFullBlock",
      "PartnersBlock",
      "QuoteBlock",
      "StepsBlock",
      "WithSidebarBlock"
    ],
    "PartsType": [
      "DefaultStreamBlock",
      "ImageBlock",
      "OwnersBlock",
      "ParagraphBlock",
      "ParagraphCalloutBlock",
      "ParagraphImageBlock",
      "PartnersBlock",
      "QuoteBlock",
      "StepsBlock"
    ],
    "PrepCollectType": [
      "AccordionBlock",
      "DefaultStreamBlock",
      "ImageBlock",
      "OwnersBlock",
      "ParagraphBlock",
      "ParagraphCalloutBlock",
      "ParagraphImageBlock",
      "ParagraphImageFullBlock",
      "PartnersBlock",
      "QuoteBlock",
      "StepsBlock",
      "WithSidebarBlock"
    ],
    "SidebarContentsType": [
      "DefaultStreamBlock",
      "ImageBlock",
      "ParagraphBlock"
    ]
  }
};
      export default result;
