// adapt the link URL between dev and production (with the possibility of a staging server)

// export const serverUri = "https://investigate.gmri.org/api/graphql"
export const serverUri =
  process.env.NODE_ENV === "development"
    ? "http://web:8080/api/graphql"
    : process.env.NEXT_PUBLIC_GRAPHQL_API ?? "https://investigate.gmri.org/api/graphql"

// export const clientUri = "https://investigate.gmri.org/api/graphql"
export const clientUri = "/api/graphql"
