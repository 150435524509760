"use client";

import { ApolloLink, HttpLink } from "@apollo/client";
import { ApolloClient, ApolloNextAppProvider, InMemoryCache, SSRMultipartLink } from "@apollo/experimental-nextjs-app-support";
import { RestLink } from "apollo-link-rest";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import React from "react";
import { setVerbosity } from "ts-invariant";
import { clientUri, serverUri } from "./uri";
import introspection from "../possible-types";
setVerbosity("debug");
function makeClient() {
  console.debug(`Apollo is trying to connect to ${serverUri} on the server and ${clientUri} on the client`);
  const serverLink = new HttpLink({
    uri: serverUri,
    fetchOptions: {
      cache: "no-store"
    }
  });
  const clientLink = ApolloLink.from([new RestLink({
    uri: "/api/v1/"
  }), createUploadLink({
    credentials: "same-origin",
    uri: clientUri
  })]);
  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: introspection.possibleTypes,
      typePolicies: {
        TripProperties: {
          keyFields: ["propId"]
        }
      }
    }),
    link: typeof window === "undefined" ? ApolloLink.from([new SSRMultipartLink({
      stripDefer: true
      // cutoffDelay: 100, // milliseconds of delay on the server before punting and letting the client load
    }), serverLink]) : clientLink
  });
}
export function ApolloWrapper({
  children
}: React.PropsWithChildren) {
  return <ApolloNextAppProvider makeClient={makeClient} data-sentry-element="ApolloNextAppProvider" data-sentry-component="ApolloWrapper" data-sentry-source-file="ApolloWrapper.tsx">{children}</ApolloNextAppProvider>;
}