"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { ApolloWrapper } from "shared/apollo/ApolloWrapper";
import { GAListener } from "shared/google-analytics";
export default function Providers({
  children
}: {
  children: React.ReactNode;
}) {
  const queryClient = new QueryClient();
  return <GAListener trackingId={process.env.NODE_ENV === "production" ? "UA-146787007-4" : undefined} data-sentry-element="GAListener" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="providers.tsx">
        <ApolloWrapper data-sentry-element="ApolloWrapper" data-sentry-source-file="providers.tsx">{children}</ApolloWrapper>
      </QueryClientProvider>
    </GAListener>;
}